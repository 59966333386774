.border{
    border: 1px solid #dfe4ea!important;
}

.border-non{
    border: none!important;
}

.img-fluid{
    max-width: 100%;
    height: auto;
}

.img-thumbnail{
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 100%;
    height: auto;
}

.rounded-3 {
    border-radius: .3rem!important;
}

.rounded-pill{
    border-radius: 30px!important;
}

.float-left{
    float: left!important;
}

.float-right{
    float: right!important;
}

.pointer{
    cursor: pointer!important;
}

.non-cursor{
    cursor: default!important;
}

/* Margin */
.m-0 {
    margin: 0 !important;
}

.m-1 {
    margin: .25rem !important;
}

.m-2 {
    margin: .5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

/* Margin Top */
.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: .25rem !important;
}

.mt-2 {
    margin-top: .5rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}

.mt-5 {
    margin-top: 3rem !important;
}

/* Magrin Button */
.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: .25rem !important;
}

.mb-2 {
    margin-bottom: .5rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.mb-5 {
    margin-bottom: 3rem !important;
}

/* Margin left */
.ml-0 {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: .25rem !important;
}

.ml-2 {
    margin-left: .5rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.ml-4 {
    margin-left: 1.5rem !important;
}

.ml-5 {
    margin-left: 3rem !important;
}

/* Margin right */
.mr-0 {
    margin-right: 0 !important;
}

.mr-1 {
    margin-right: .25rem !important;
}

.mr-2 {
    margin-right: .5rem !important;
}

.mr-3 {
    margin-right: 1rem !important;
}

.mr-4 {
    margin-right: 1.5rem !important;
}

.mr-5 {
    margin-right: 3rem !important;
}

/* Padding */
.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: .25rem !important;
}

.p-2 {
    padding: .5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

/* ukuran text */
.f-1{
    font-size: 10px;
}

.f-2{
    font-size: 12px;
}

.f-3{
    font-size: 14px;
}

.input{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 8px 11px 5px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

/* text */
.text-italic{
    font-style: italic;
}

.text-center{
    text-align: center!important;
}

.text-left{
    text-align: left!important;
}

.text-right{
    text-align: right!important;
}

.text-justify{
    text-align: justify;
}

.text-muted{
    /* color: rgb(197, 197, 197)!important; */
    color: #6e7190!important;
}

.text-primary{
    /* color: #0d6efd!important; */
    color: #233ef9!important;
}

.text-dark{
    color: #212529!important;
}

.text-bold{
    font-weight: bold!important;
}

.text-break {
    word-wrap: break-word!important;
    word-break: break-word!important;
}

.bg-non{
    background: transparent!important;
}

.scroll{
    overflow: auto;
    display: block;
    width: 100%;
}

::-webkit-scrollbar {
    display: none;
}

.shadow-sm{
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

.shadow{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

/* flex */
.d-flex{
    display: flex!important;
}

.justify-content-start{
    justify-content: start!important;
}

.justify-content-end{
    justify-content: end!important;
}

.justify-content-center{
    justify-content: center!important;
}

.justify-content-between{
    justify-content: space-between!important;
}

.justify-content-around{
    justify-content: space-around!important;
}

.justify-content-evenly{
    justify-content: space-evenly!important;
}

.flex-grow-1 {
    flex-grow: 1!important;
}

.w-100{
    width: 100%!important;
}

.w-75{
    width: 75%!important;
}

.w-50{
    width: 50%!important;
}

.w-25{
    width: 25%!important;
}