@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.App {
  text-align: center;
}

* {
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
}

.Row {
  display: table;
  width: 100%; /*Optional*/
  table-layout: fixed; /*Optional*/
}

.Column {
  display: table-cell;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  display: none;
}

.Custom-Menu{
  font-size: 13px;
  padding-left : 10px !important; 
  border-radius : 12px; 
  margin : 10px 20px !important;
  padding-top :5px !important; 
  width: 85% !important;
}

.Custom-Sub-Menu{
  font-size: 13px;

  width: 85% !important;
  border-radius : 12px !important; 
  padding-left : 5px;
  margin : 10px 30px 10px 15px !important;
  /* padding-right : 10px; */
}

span.anticon.ant-menu-item-icon{
  float : left !important;
}

div.ant-menu-submenu-title > span.anticon {
  float : left !important;
  /* margin-left :  */
}

.bg-header{
  color: #61dafb;
}