* {
    font-family: poppins !important;
    box-sizing: border-box;
}

/* news */
.input-news{
    width: 100%;
    height: 40px!important;
    background-color: #d7d8dd!important;
    border: none;
    color: #6e7190!important
}

.input-news:hover .input-news:active{
    background-color: #d7d8dd!important;
}

/* write news */
.box-image{
    height: auto;
    width: 70px;
}

.box-image img{
    width: 100%;
    height: 70px;
    border-radius: 100%;
}

.content-news{
    margin-bottom: auto;
    margin-left: 82px;
    margin-top: -55px;
    position: relative;
}

.icon-user{
    width: 100%;
    height: 67px;
    margin-bottom: -4px;
}

.content-icon{
    display: flex;
    justify-content:space-between;
    margin-top: 20px;
}

.image-upload > input{
    display: none!important;
    cursor: pointer!important;
}

.text-icon{
    margin-left: 10px;
    font-size: 13px;
    color: #6e7190;
    margin-bottom: auto;
    margin-top: 4px;
    float: right;
    cursor: pointer!important;
}

.box-taps{
    background-color: #d7d8dd!important;
    border: none;
    border-radius: 10px;
    text-align: left;
    height: 50px;
    margin-top: -18px;
}

.box-taps p{
    margin: 0px;
    padding-top: 10px;
    color: #6e7190;
    font-size: 13px;
}

.taps-btn{
    width: 100%;
    background: transparent!important;
    padding-left: 10px;
    text-align: left!important;
    color: #d7d8dd!important;
}

/* cari data */
.search-news{
    /* border-radius: 10px!important; */
    height: 40px!important;
    border: none!important;
    margin-bottom: 15px!important;
    padding: 12px 20px 12px 45px!important;
    background-position: 10px 7px!important;
    background-image: url('../svg/line/search_line.svg')!important;
    background-repeat: no-repeat!important;
    color: #4e4b66!important;
}

.costum-modal .ant-modal-content, .costum-modal .ant-modal-content .ant-modal-header {
    /* border-radius: 20px 20px 20px 20px; */
    text-align: center;
    border: none;
}
/* end write news */

/* comment news */
.image-header{
    height: auto;
    width: 53px;
}

.image-header img{
    width: 100%;
    height: 55px;
    border-radius: 100%;
}

.icon-header{
    width: 100%;
    height: 55px;
    margin-bottom: -6px;
}

.header-title{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 65px;
    margin-top: -48px;
}

.header-title span{
    font-weight: bold;
    font-size: 14px;
}

.header-title p{
    font-size: 12px;
    color: #a0a3bd!important;
}

.desc-header{
    text-align: justify;
    margin-top: 20px;
    font-size: 14px;
    margin-bottom: -10px;
}

.img-size-content{
    width: 100%;
    margin-bottom: 10px;
    border-radius: 25px;
    height: 270px!important;
    margin-top: 20px;
}

.news-sent{
    margin-bottom: auto;
    margin-left: 27px;
    margin-top: -24px;
    float: right;
    font-size: 13px;
    color: #4e4b66!important;
}

/* comment */
.image-comment{
    height: auto;
    width: 42px;
    margin-top: 15px;
}

.image-comment img{
    width: 100%;
    height: 43px;
    border-radius: 100%;
}

.commet-title{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 55px;
    margin-top: -47px;
}

.commet-title span{
    font-size: 15px;
    font-weight: bold!important;
}

.commet-title p{
    font-size: 14px;
    margin: 0px!important;
    color: #4f4b66!important;
}

.commet-title .commet-footer{
    font-size: 13px;
    margin: 0px!important;
    color: #a0a2bd!important;
}

.comment-news{
    margin-bottom: auto;
    margin-left: 55px;
    margin-top: -36px;
    position: relative;
    border-radius: 10px;
}

.btn-model-none{
    text-align: center;
    width: 100%;
    font-weight: bold!important;
    color: #233ef9!important;
    border: none!important;
}
/* end comment news */

/* modal filter satker */
.box-filter{
    box-sizing: border-box;
    display: inline-block;
    width: 100%!important;
    padding: 4px 11px;
    font-size: 14px;
    margin-bottom: 15px;
    cursor: pointer;
    border: 1px solid #dfe4ea!important;
}

.box-filter:hover{
    background: #e7ebf0;
}

.img-satker{
    height: auto;
    width: 40px;
}

.img-satker img{
    width: 100%;
    height: 40px;
    border-radius: 100%;
}

.satker{
    margin-bottom: 5px;
    margin-left: 53px;
    margin-top: -32px;
    font-size: 13px;
    font-weight: bold;
}
/* end modal filter satker */

/* tentukan status pimpinan */
.img-forward{
    height: auto;
    width: 45px;
}

.img-forward img{
    width: 100%;
    height: 45px;
    border-radius: 100%;
}

.forward{
    margin-top: auto;
    margin-bottom: 2px;
    margin-left: 53px;
    margin-top: -43px;
}

.forward span{
    font-size: 13px;
    font-weight: bold!important;
}

.forward p{
    font-size: 11px;
    margin: 0px!important;
}

.local-icon{
    float: left;
    margin-top: 6px!important;
}

.local-text{
    margin-top: auto;
    margin-left: 30px;
    font-size: 12px;
}
/* end tentukan status pimpinan */

/* pilih wilayah */
.box-filters{
    box-sizing: border-box ;
    display: inline-block;
    width: 100%!important;
    padding: 4px 11px!important;
    font-size: 14px;
    margin-bottom: 15px;
    border-bottom: 1px solid #dedede;
}
/* end pilih wilayah */

.news-audio{
    width: 100%;
    outline: none!important;
    border: none!important;
    margin-top: 25px;
    height: 45px;
}
/* end news */

/* performance */
.text-profile{
    margin: 0px;
    font-size: 13px;
    margin-top: 10px;
}

.text-profile-muted{
    margin: 0px;
    font-size: 12px;
    color: #6e7190;
}

.img-rank-performa{
    width: 70px!important;
    height: 70px!important;
}

.text-rank-performa{
    margin-top: -50px;
    position: relative;
    margin-bottom: 33px;
    font-size: 10px;
}

.img-rank-level{
    width: 80px!important;
    height: 80px!important;
}

.text-rank-level{
    margin-top: -55px;
    position: relative;
    margin-bottom: 38px;
    font-size: 10px;
}

.text-level{
    margin: 0px;
    font-size: 15px;
    margin-top: 10px;
}

.text-level-muted{
    margin: 0px;
    font-size: 14px;
    color: #6e7190;
}

/* performa agen lain */
.image-perform{
    height: auto;
    width: 42px;
}

.image-perform img{
    width: 100%;
    height: 43px;
    border-radius: 100%;
    margin-top: 10px;
}

.icon-perform{
    width: 100%;
    height: 42px;
    border-radius: 10px;
    margin-bottom: -4px;
    margin-top: 10px;
}

.perform-title{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 55px;
    margin-top: -43px;
}

.perform-title span{
    font-size: 13px;
    font-weight: bold!important;
}

.perform-title p{
    font-size: 12px;
    margin: 0px!important;
}

.text-perform{
    text-align: center;
    font-size: 12px;
    margin-top: 15px;
    float: right;
}

.option-filter{
    width: 185px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.card-satker{
    border-radius: 15px;
    margin-top: 20px;
    margin-bottom: 25px!important;
}

.btn-satker{
    border-radius: 30px!important;
    margin-right: 15px;
}

.btn-news{
    color: #233ef9;
    margin-top: 10px;
    float: left;
}
/* end performance */

/* profile */
.profile-img{
    width: 90px;
    border-radius: 100%;
    margin-bottom: 10px;
}

.icon-profile{
    width: 100%;
    height: 90px;
}

.img-card{
    width: 70px;
    border-radius: 100%;
    margin-bottom: 5px;
}

.emp-card{
    margin-top: 10px!important;
    border-radius: 7px!important;
    color: #fff!important;
    border-radius: 14px!important;
    background-image: url('../image/kejaksaan.png')!important;
}

.img-qrcode{
    width: 8vh;
    height: 8vh;
    padding: 3px;
    margin-bottom: -20px;
    position: relative;
    z-index: 1;
}  

.emp-card-profile{
    border-radius: 12px!important;
    background: #447e22!important;
    opacity: 0.9!important;
    color: #fff!important;
    border: none!important;
    margin-bottom: 18px!important;
}

.img-profile{
    width: 13vh;
    height: 14vh;
    border-radius: 10px;
    margin-top: -50px;
    position: relative;
}

.logo-kejaksaan{
    width: 7vh;
    height: 7vh;
    padding: 10px;
    display: inline-block;
    border-radius: 10px;
    z-index: 1;
    position: relative;
    margin-bottom: -15px;
    background: #fff;
}
/* end profile */

/* public reports */
.container {
    position: relative;
    width: 100%;
}
.content {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    margin-top: -98px;
}

.container:hover .image {
    opacity: 0.3;
}

.container:hover .content {
    opacity: 1;
}

.text {
    color: white;
    padding: 16px 32px;
    width: 100%;
    background-color: #b8b8b85d;
}

.btn-img{
    height: auto;
    width: 40px;
}

.btn-img img{
    width: 100%;
    height: 40px;
    border-radius: 100%;
}

.text-img{
    margin-left: 40px;
    margin-top: -24px;
    font-size: 13px;
    font-weight: bold;
}
/* end public reports */

/* user management */
.bg-header{
    background: transparent!important;
    padding: 1rem!important;
    margin-bottom: 7px!important;
    color: #6e7190!important;
    border: none!important;
}

.bg-content{
    border-radius: 7px!important;
    margin-bottom: 10px!important;
    border: 1px solid #d9dbe9!important;
    word-break: break-word!important;
}

.btn-icon{
    display: flex;
    justify-content:space-around;
    padding: none!important;
}

.btn-icon Button{
    padding: 0px!important;
    height: 0px!important;
    margin-right: 20px;
    background: transparent!important;
}

.box-costum{
    background-color: #d7d8dd!important;
    border: none;
    border-radius: 10px;
    text-align: left;
    /* margin-bottom: .7rem; */
    margin-bottom: -10px;
    cursor: pointer;
}

.box-costum p{
    margin: 0px;
    padding-top: 8px;
    padding-left: 10px;
    color: #6e7190;
    font-size: 14px;
    margin-bottom: 3px;
}

.flex-ops{
    display: flex;
    justify-content: space-evenly;
    height: auto;
    margin-bottom:25px;
    margin-top: 25px;
}

.text-ops{
    margin: 0px;
    margin-right: 5px;
    /* margin-left: 5px; */
    margin-top: 5px;
}

.text-opsi{
    margin: 0px;
    margin-right: 15px;
    margin-left: 32px;
    margin-top: 5px;
}
/* end user management */


/* login */
body{
    background: #eff0f5!important;
}

.box-login{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 30em;
    height: auto; 
    border-radius: 15px;
    transform: translate(-50%, -50%);
}

.input-kode{
    height: 50px!important;
    width: 50%!important;
    text-align: center!important;
    font-size: 24px!important;
    font-weight: bold!important;
}

.text-kode{
    color: #233EF9!important;
    padding: 0px!important;;
    font-size: 13px!important;
    background: transparent!important;
}

.mbtn{
    margin-top: 7px!important;
    height: 50px!important;
    width: 60%!important;
    float: right!important;
    margin-right: 10px!important;
}

/* respon pimoinan */
.image-headers{
    height: auto;
    width: 53px;
}

.image-headers img{
    width: 100%;
    height: 55px;
    border-radius: 100%;
}

.icon-headers{
    width: 100%;
    height: 50px;
    margin-bottom: -3px;
}

.headers-title{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 12px;
    /* margin-top: -48px; */
}

.headers-title span{
    font-weight: bold;
    font-size: 13px;
}

.headers-title p{
    font-size: 11px;
    color: #a0a3bd!important;
}

.files{
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 14px;
    color: rgb(153,153,153);
    width: 31vh;
    margin-top: -15px;
}

.filter-satker Button{
    padding-left: 6px!important;
    padding-right: 6px!important;
    margin-right: 5px!important;
    height: 39px!important;
}

.img-news{
    width: 110px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.icon-up{
    color: #233ef9;
    font-size: 40px;
    margin-top: 9px;
    margin-right: 10px;
}

.icon-down{
    color: #eb5758;
    font-size: 40px;
    margin-top: 9px;
    margin-right: 10px;
}

.costume-box{
    border: 1px solid #d9dbe9!important;
    font-size: 13px!important;
    border-radius: 7px;
    background-color: #fff;
    background-image: url('../svg/line/hamburger-menu.svg')!important;
    background-repeat: no-repeat!important;
    background-size: 15px;
    padding-right: 28px!important;
    background-position: 95%;
    padding: 7px 27px 7px 12px!important;
}

/* crawling box radius */
.bubble{
    border-radius: 100%;
    padding: 25px 15px 15px 15px!important;
    max-height: 120px!important;
    max-width: 120px!important;
    height: 110px;
    text-align: center;
    word-wrap: break-word;
    color: #fff;
    font-size: 12px;
}

.box{
    background: #ff4d4f;
    border-radius: 7px;
    color: #fff;
    font-size: 13px;
    width: 70px;
    word-wrap: break-word;
    height: auto;
    padding: 5px 5px 5px 5px;
    display: inline-block;
    margin-bottom: 10px;
}

/* profile detail unggahan */
.upload-header{
    height: auto;
    width: 40px;
}

.upload-header img{
    width: 100%;
    height: 40px;
    border-radius: 100%;
}

.upload-icon-header{
    width: 100%;
    height: 40px;
    margin-bottom: -5px;
}

.upload-header-title{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 50px;
    margin-top: -42px;
}

.upload-header-title span{
    font-weight: bold;
    font-size: 12px;
}

.upload-header-title p{
    font-size: 11px;
    color: #a0a3bd!important;
}

/* image modal news (who) */
.img-size-news{
    width: 100%;
    border-radius: 15px;
    height: 140px!important;
}

/* notifikasi */
.box-notif-aktif{
    background: #f2f5ff;
    padding: 17px 10px 1px 15px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.box-notif-aktif:hover{
    background: #e5e9f7;
    cursor: pointer;
}

.box-notif-non{
    background: #fff;
    padding: 17px 10px 1px 15px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.box-notif-non:hover{
    background: #f0f2f5;
    cursor: pointer;
}

.notif-header{
    height: auto;
    width: 40px;
}

.notif-header img{
    width: 100%;
    height: 40px;
    border-radius: 100%;
}

.notif-icon-header{
    width: 100%;
    height: 40px;
    margin-bottom: -5px;
}

.notif-header-title{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 50px;
    margin-top: -42px;
}

.notif-header-title span{
    font-size: 12px;
    color: #000;
    font-weight: bold;
}

.notif-header-title p{
    font-size: 11px;
    color: #000;
    word-wrap: break-word!important;
}

.box-message{
    cursor: pointer;
    margin-bottom: 10px;
    padding: 7px 7px 7px 7px;
}

.box-message:hover{
    background: #f0f2f5;
    cursor: pointer;
    border-radius: 10px;
}

.hover-grey{
    background: transparent;
}

.hover-grey:hover{
    background: #ebf1ff!important;
}

.hover-folder{
    background: transparent;
    padding: 5px;
    cursor: pointer;
}

.hover-folder:hover{
    background: #f0f2f5!important;
    border-radius: 10px;
}

.tag-actif{
    background: #e5ebfe;
    color: #2149ce;
    padding: 2px 7px 2px 7px;
    border-radius: 10px;
    font-size: 13px;
    margin-left: 20px;
    margin-bottom: 13px;
}

.img-actif{
    border-radius: 100%;
    width: 25px;
    height: 25px;
    margin-top: -2px;
}

.img-share{
    border-radius: 100%;
    width: 40px;
    height: 40px;
    margin-top: -2px;
}

.design-box{
    display: inline-block;
    font-weight: 400;
    background: #d7d8dd;
    color: #6e7190;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 3px 3px 3px 5px;
    font-size: 14px;
    border-radius: 10px;
    margin: .25rem .125rem;
}

.h-primary:hover{
    background: #e8effe!important;
    
}

.bhover{
    padding: 4px 12px;
    border-radius: 10px;
    display: inline-block;
    background: transparent;
    text-align: center;
}

.bhover:hover{
    padding: 4px 12px;
    border-radius: 10px;
    display: inline-block;
    background: #f0f2f5;
    text-align: center;
    cursor: pointer;
}

input[type=time]{
    border-radius: 7px;
    border: 1px solid #dfe4ea;
    padding: 3px 10px;
    cursor: pointer!important;
    width: 12vh;
}

/* digital identification */
.bg-cantainer{
    background-image: url('../image/bg-digital.png');
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    height: 100vh;
}

.containers {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto; 
    border-radius: 15px;
    transform: translate(-50%, -50%);
}

.img-kejaksaan{
    width: 8vh;
    height: 8vh;
    padding: 10px;
    border-radius: 10px;
    z-index: 1;
    position: relative;
    margin-bottom: -10px;
    background: #fff;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.image-profile{
    width: 13vh;
    height: 14vh;
    border-radius: 10px;
    margin-top: -50px;
    position: relative;
}

.emp-card-profile-red{
    border-radius: 12px!important;
    background: #dc2c2c!important;
    opacity: 0.9!important;
    color: #fff!important;
    border: none!important;
    margin-bottom: 18px!important;
    text-align: center!important;
    height: 180px;
}

.img-profile-close{
    width: 8vh;
    height: 8vh;
    border-radius: 10px;
    margin-top: -55px;
    position: relative;
}

.gradient-red{
    background: linear-gradient(180deg, rgba(252,232,232,1) 8%, rgba(255,255,255,1) 45%)!important;
}
