.filter-btn{
    box-sizing: border-box;
    padding: 4px 11px;
    font-size: 14px;
    color: #54516b!important;
    border-radius: .3rem!important;
    margin-right: 20px;
}

.filter-top{
    top: -5px!important;
}

.search-global{
    float: right!important;
    max-width: 25vh!important;
    border-radius: 35px!important;
    padding: 6px 16px!important;
    border: 1px solid #a6b0bb!important;
    background: #fff!important;
}

.search-global:hover{
    cursor: pointer;
    border: 1px solid #1e3ef9!important;
}